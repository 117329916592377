import React, { useState, useRef } from 'react'
import { Button, Modal } from '@mui/material'
import styled from 'styled-components'
import { mobileCss } from 'utils/theme'
import { toast } from 'react-hot-toast'
import { supabase } from 'utils/supabase'
import { generateRandomString } from 'utils/generators'

interface Props {
  imageUrl: string | null
  onUpdate: (url: string | null) => void
  vipId?: string
}

const ImageManager = ({ imageUrl, onUpdate, vipId }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [previewUrl, setPreviewUrl] = useState<string | null>(null)
  const [isUploading, setIsUploading] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleFileSelect = (file: File) => {
    setSelectedFile(file)
    const reader = new FileReader()
    reader.onloadend = () => {
      setPreviewUrl(reader.result as string)
    }
    reader.readAsDataURL(file)
  }

  const handleConfirm = async () => {
    if (!selectedFile || !vipId) return

    try {
      setIsUploading(true)
      const fileType = selectedFile.type.split('/')[1]
      const newFileName = `${generateRandomString()}.${fileType}`
      const renamedFile = new File([selectedFile], newFileName, { type: selectedFile.type })

      const { data, error } = await supabase
        .storage
        .from('pictures_wiw')
        .upload(newFileName, renamedFile)

      if (error) {
        console.error('Upload error:', error)
        toast.error('Coś poszło nie tak')
        return
      }

      const { data: imageData } = supabase.storage
        .from('pictures_wiw')
        .getPublicUrl(data.path)

      if (imageData) {
        await supabase.from('vip_links')
          .update({ vipImageUrl: imageData.publicUrl })
          .eq('id', vipId)

        onUpdate(imageData.publicUrl)
        toast.success('Zdjęcie zostało dodane')
        handleCloseModal()
      }
    } catch (error) {
      console.error('Error uploading image:', error)
      toast.error('Wystąpił błąd podczas dodawania zdjęcia')
    } finally {
      setIsUploading(false)
    }
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setSelectedFile(null)
    setPreviewUrl(null)
  }

  const handleRemove = () => {
    setIsDeleteModalOpen(true)
  }

  const confirmDelete = async () => {
    if (!vipId) return

    try {
      const { data: vipData } = await supabase
        .from('vip_links')
        .select('vipImageUrl')
        .eq('id', vipId)
        .single()

      if (vipData?.vipImageUrl) {
        const fileName = vipData.vipImageUrl.split('/').pop()
        
        if (fileName) {
          const { error: storageError } = await supabase
            .storage
            .from('pictures_wiw')
            .remove([fileName])

          if (storageError) {
            console.error('Error removing file from storage:', storageError)
          }
        }
      }

      await supabase.from('vip_links')
        .update({ vipImageUrl: null })
        .eq('id', vipId)

      onUpdate(null)
      toast.success('Zdjęcie zostało usunięte')
    } catch (err) {
      console.error('Error removing image:', err)
      toast.error('Wystąpił błąd podczas usuwania zdjęcia')
    }

    setIsDeleteModalOpen(false)
  }

  const handleFileButtonClick = () => {
    fileInputRef.current?.click()
  }

  return (
    <Wrapper>
      {imageUrl ? (
        <img style={{ width: '200px', height: 'auto' }} src={imageUrl} alt='profile' />
      ) : (
        <Placeholder />
      )}
      <ButtonsWrapper>
        <Button 
          variant="outlined" 
          onClick={() => setIsModalOpen(true)}
        >
          Dodaj zdjęcie
        </Button>
        <Button 
          variant="outlined"
          color="error"
          onClick={handleRemove}
          disabled={!imageUrl}
        >
          Usuń zdjęcie
        </Button>
      </ButtonsWrapper>

      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
      >
        <ModalContent>
          <ModalTitle>Dodaj zdjęcie</ModalTitle>
          <FileInputWrapper>
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={(e) => {
                if (e.target.files?.[0]) {
                  const file = e.target.files[0]
                  if (!file.type.startsWith('image/')) {
                    toast.error('Dozwolone są tylko pliki graficzne')
                    return
                  }
                  if (file.size > 5 * 1024 * 1024) {
                    toast.error('Maksymalny rozmiar pliku to 5MB')
                    return
                  }
                  handleFileSelect(file)
                }
              }}
            />
            <Button
              variant="outlined"
              onClick={handleFileButtonClick}
              fullWidth
            >
              {selectedFile ? selectedFile.name : 'Wybierz zdjęcie z dysku'}
            </Button>
          </FileInputWrapper>
          {previewUrl && (
            <PreviewImage src={previewUrl} alt="preview" />
          )}
          <ModalButtonsWrapper>
            <Button 
              variant="outlined" 
              onClick={handleCloseModal}
            >
              Anuluj
            </Button>
            <Button 
              variant="contained"
              onClick={handleConfirm}
              disabled={!selectedFile || isUploading}
            >
              {isUploading ? 'Dodawanie...' : 'Zapisz'}
            </Button>
          </ModalButtonsWrapper>
        </ModalContent>
      </Modal>

      <Modal open={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
        <ModalContent>
          <ModalTitle>Potwierdź usunięcie</ModalTitle>
          <DeleteModalText>
            Czy na pewno chcesz usunąć zdjęcie profilowe?
          </DeleteModalText>
          <ModalButtonsWrapper>
            <Button 
              variant="outlined" 
              onClick={() => setIsDeleteModalOpen(false)}
            >
              Anuluj
            </Button>
            <Button 
              variant="contained"
              color="error"
              onClick={confirmDelete}
            >
              Usuń
            </Button>
          </ModalButtonsWrapper>
        </ModalContent>
      </Modal>
    </Wrapper>
  )
}

export default ImageManager

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 400px;
`

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  width: 100%;

  > button {
    flex: 1;
    min-width: 0;
    padding: 6px 8px;
    
    > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`

const ModalButtonsWrapper = styled(ButtonsWrapper)`
  justify-content: flex-end;

  > button {
    flex: initial;
    padding: 6px 16px;
    
    > span {
      overflow: visible;
    }
  }
`

const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 32px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const ModalTitle = styled.h2`
  margin: 0;
  color: ${({ theme }) => theme.colors.secondary};
`

const FileInputWrapper = styled.div`
  width: 100%;
`

const DeleteModalText = styled.div`
  text-align: center;
  line-height: 1.6;
`

const PreviewImage = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 4px;
`

const Placeholder = styled.div`
  width: 200px;
  height: 200px;
  border: 3px solid ${({ theme }) => theme.colors.primary};
  border-radius: 6px;
`