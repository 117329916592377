import React, { useState } from 'react'
import styled from 'styled-components'
import { Accordion, AccordionSummary, AccordionDetails, Typography, Button, Modal } from '@mui/material'
import VipMenu from 'admin/components/regular/VipMenu'
import { mobileCss } from 'utils/theme'
import { useNavigate, useParams } from 'react-router-dom'
import VipPageWrapper from 'admin/components/VipPageWrapper'
// Add image import
// @ts-ignore
import zapiszImg from 'assets/zapisz.png'
// @ts-ignore
import podgladImg from 'assets/podglad.png'
// @ts-ignore
import menuImg from 'assets/menu.png'
// @ts-ignore
import edytujImg from 'assets/edytuj.png'
// @ts-ignore
import zdjecieImg from 'assets/zdjecie.png'
// @ts-ignore
import panelEdycjiImg from 'assets/panel_edycji.png'
// @ts-ignore
import okoImg from 'assets/oko.png'
// @ts-ignore
import zgloszenieImg from 'assets/zgloszenie.png'
// @ts-ignore
import prezentacjeImg from 'assets/prezentacje.png'
// @ts-ignore
import dodawanieImg from 'assets/dodawanie.png'
// @ts-ignore
import usuwanieImg from 'assets/usuwanie.png'

const Instructions = () => {
  const navigate = useNavigate()
  const { code } = useParams()
  const [isImageModalOpen, setIsImageModalOpen] = useState(false)
  const [modalImage, setModalImage] = useState('')
  const [isContactModalOpen, setIsContactModalOpen] = useState(false)

  const handleImageClick = (imageSrc: string) => {
    setModalImage(imageSrc)
    setIsImageModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsImageModalOpen(false)
    setModalImage('')
  }

  const handleReportClick = () => {
    setIsContactModalOpen(true)
  }

  return (
    <VipPageWrapper>
      <Header>
        <ButtonsWrapper>
          <Button 
            variant='outlined' 
            onClick={() => navigate(`/${code}`)}
            sx={{ marginRight: 1 }}
          >
            Powrót
          </Button>
          <Button 
            variant='outlined' 
            onClick={() => navigate(`/${code}/broszura/wszyscy`)}
          >
            Zobacz wszystkich
          </Button>
        </ButtonsWrapper>
      </Header>
      <ContentWrapper>
        <InstructionsTitle>Jak używać strony WhoIsWho w PRB: pytania i odpowiedzi</InstructionsTitle>
        
        <AccordionsWrapper>

        <Accordion>
            <AccordionSummary
              expandIcon={<ExpandIcon>+</ExpandIcon>}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              <Typography>1. Jak się zaloguję?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Biuro PRB udostępnia indywidualny link dla każdego członka organizacji. Jeżeli zgubiłeś swój link dostępowy – napisz do Karoliny Szocińskiej (<a href="mailto:k.szocinska@prb.pl">k.szocinska@prb.pl</a>) z prośbą o utworzenie nowego.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandIcon>+</ExpandIcon>}
              aria-controls="panel5-content"
              id="panel5-header"
            >
              <Typography>2. Kto uzupełnia dane?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              Uzupełnij dane samodzielnie. Pod żadnym pozorem nie przekazuj nikomu linku dostępowego.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandIcon>+</ExpandIcon>}
              aria-controls="panel4-content"
              id="panel4-header"
            >
              <Typography>3. Jak dodam swoje dane?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                W menu po lewej wybierz drugą od góry opcję "Edytuj swój profil".
                <ImageWrapper onClick={() => handleImageClick(edytujImg)}>
                  <img src={edytujImg} alt="Przycisk edytuj swój profil" />
                  <ZoomHint>Kliknij, aby powiększyć</ZoomHint>
                </ImageWrapper>
                Wszystkie pola są opcjonalne, ale zachęcamy do wypełnienia podstawowych informacji.
                <br /><br />
                Zdjęcie dodaj korzystając z przycisków na samej górze. Najpierw kliknij "Wybierz zdjęcie", a następnie "Wybierz zdjęcie z dysku", żeby wgrać plik z Twojego urządzenia. Kliknij "Potwierdź", żeby zdjęcie pojawiło się na Twoim profilu. Jeżeli potrzebujesz usunąć wgrane zdjęcie to kliknij "Usuń zdjęcie". 
                
                <ImageWrapper onClick={() => handleImageClick(zdjecieImg)}>
                  <img src={zdjecieImg} alt="Sekcja dodawania zdjęcia" />
                  <ZoomHint>Kliknij, aby powiększyć</ZoomHint>
                </ImageWrapper>

                Pod miejscem dodawania zdjęcia znajdziesz wszystkie edytowalne sekcje:
                <ul>
                  <li>O mnie;</li>
                  <li>Moje firmy;</li>
                  <li>Moja działalność społeczna;</li>
                  <li>Działalność członków rodziny;</li>
                  <li>Zainteresowania/hobby;</li>
                  <li>Dodatkowe informacje;</li>
                  <li>Moje prezentacje;</li>
                  <li>Dane kontaktowe.</li>
                </ul>

                <WarningText>
                UWAGA! Zawsze zapisz zmiany przyciskiem "Zapisz" w prawym górnym rogu.
              </WarningText>
              W części "Moje prezentacje" kliknij "Dodaj prezentację z dysku", żeby dodać plik pdf z prezentacją. Możesz dodać do 10 prezentacji, każdy o rozmiarze maksymalnie 10MB.
              </Typography>
              <br />
              <ImageWrapper onClick={() => handleImageClick(prezentacjeImg)}>
                  <img src={prezentacjeImg} alt="Sekcja z prezentacjami" />
                  <ZoomHint>Kliknij, aby powiększyć</ZoomHint>
                </ImageWrapper>
              <br />
              <Typography>
                W każdej sekcji możesz korzystać z prostego panelu do edycji tekstu (podobnym jak w MS Word czy Google Docs). Możesz tam zmieniać typ i wielkość czcionki, dodawać hiperłącza, listy, podkreślenia czy pogrubienia, a także ustawiać wyjustowanie.

                <ImageWrapper onClick={() => handleImageClick(panelEdycjiImg)}>
                  <img src={panelEdycjiImg} alt="Panel edycji tekstu" />
                  <ZoomHint>Kliknij, aby powiększyć</ZoomHint>
                </ImageWrapper>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandIcon>+</ExpandIcon>}
              aria-controls="panel6-content"
              id="panel6-header"
            >
              <Typography>4. Czy wszystko się zapisze samo?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              Nie. Po wprowadzeniu danych, zawsze potrzebujesz kliknąć przycisk "Zapisz", który mieści się w prawym górnym rogu. Inaczej wprowadzone dane znikną.
                <ImageWrapper onClick={() => handleImageClick(zapiszImg)}>
                  <img src={zapiszImg} alt="Przycisk zapisz w prawym górnym rogu" />
                  <ZoomHint>Kliknij, aby powiększyć</ZoomHint>
                </ImageWrapper>
                <br/>
                Przycisk "Podgląd" – który pozwala sprawdzić jak inni będą widzieć Twój profil – również zapisze wprowadzone dane.
                <br/>
                <ImageWrapper onClick={() => handleImageClick(podgladImg)}>
                  <img src={podgladImg} alt="Przycisk podgląd w prawym górnym rogu" />
                  <ZoomHint>Kliknij, aby powiększyć</ZoomHint>
                </ImageWrapper>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandIcon>+</ExpandIcon>}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography>5. Jak wrócić do poprzedniej strony?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              Do poprzedniej strony zawsze przeniesie Cię przycisk "Powrót", który mieści się w prawym górnym rogu. Możesz też użyć przycisku "Strona główna" w menu po lewej stronie, który przeniesie Cię zawsze do listy wszystkich członków.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandIcon>+</ExpandIcon>}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>6. Gdzie jest menu?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <strong>Na komputerze</strong> menu powinieneś widzieć po lewej stronie.
                <br /><br />
                <strong>Na telefonie czy tablecie</strong> w lewym górnym rogu powinna znajdować się ikona (≡). Kliknij w nią to rozwinie się identyczne menu jak to na komputerze.
                <br /><br />
                W menu możesz:
                <ul>
                  <li>przeczytać opis członka lub członkini PRB - kliknij w imię i nazwisko (np. "Wojciech Kostrzewa");</li>
                  <li>wrócić na stronę główną z listą osób - kliknij w przycisk "Strona główna";</li>
                  <li>wprowadzić swoje dane - kliknij w przycisk "Edytuj swój profil";</li>
                  <li>przeczytać jak używać WhoIsWho - kliknij w przycisk "Instrukcja";</li>
                  <li>zgłosić problem ze stroną - kliknij w przycisk "Zgłoś problem".</li>
                </ul>
                <ImageWrapper onClick={() => handleImageClick(menuImg)}>
                  <img src={menuImg} alt="Menu boczne aplikacji" />
                  <ZoomHint>Kliknij, aby powiększyć</ZoomHint>
                </ImageWrapper>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandIcon>+</ExpandIcon>}
              aria-controls="panel0-content"
              id="panel0-header"
            >
              <Typography>7. Gdzie mogę zgłosić problem?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Najlepiej kliknij w przycisk "Zgłoś problem" w menu po lewej lub napisz do:
                <ul>
                  <li>Jana Burzyńskiego (<a href="mailto:j.burzynski@prb.pl">j.burzynski@prb.pl</a>);</li>
                  <li>Karoliny Szocińskiej (<a href="mailto:k.szocinska@prb.pl">k.szocinska@prb.pl</a>).</li>
                </ul>
                <ImageWrapper onClick={() => handleImageClick(zgloszenieImg)}>
                  <img src={zgloszenieImg} alt="Formularz zgłaszania problemu" />
                  <ZoomHint>Kliknij, aby powiększyć</ZoomHint>
                </ImageWrapper>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandIcon>+</ExpandIcon>}
              aria-controls="panel7-content"
              id="panel7-header"
            >
              <Typography>8. Gdzie mogę sprawdzić jak inni widzą mój profil?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              Na stronie głównej oraz w menu po lewej stronie możesz zawsze zobaczyć podgląd swojego profilu tak, jak widzą go inni członkowie. Zatem ALBO w menu bocznym kliknij w swoje imię i nazwisko, żeby przejść do strony z opisem, ALBO na stronie głównej kliknij w ikonę oka przy wybranym imieniu i nazwisku.
              <ImageWrapper onClick={() => handleImageClick(okoImg)}>
                  <img src={okoImg} alt="Podgląd profilu" />
                  <ZoomHint>Kliknij, aby powiększyć</ZoomHint>
                </ImageWrapper>
                <br/>
                Dodatkowo, jeżeli edytujesz swój profil w części "Edytuj swój profil", w prawym górnym rogu możesz kliknąć przycisk "Podgląd". W ten sposób nie tylko sprawdzisz jak inni będą widzieć Twój profil, ale też od razu zapiszesz wprowadzone dane.
                <br/>
                <ImageWrapper onClick={() => handleImageClick(podgladImg)}>
                  <img src={podgladImg} alt="Podgląd profilu" />
                  <ZoomHint>Kliknij, aby powiększyć</ZoomHint>
                </ImageWrapper>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandIcon>+</ExpandIcon>}
              aria-controls="panel8-content"
              id="panel8-header"
            >
              <Typography>9. Gdzie znajdę informacje o członkach?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              Na stronie głównej oraz w menu po lewej stronie możesz zawsze wybrać profil członka lub członkini, który chcesz przeczytać. Zatem ALBO w menu bocznym kliknij w wybrane imię i nazwisko, żeby przejść do strony z opisem, ALBO na stronie głównej kliknij w ikonę oka przy wybranym imieniu i nazwisku.
                <br />
                <br />
                W ten sam sposób możesz też zawsze zobaczyć podgląd swojego profilu tak, jak widzą go inni członkowie.
                <ImageWrapper onClick={() => handleImageClick(okoImg)}>
                  <img src={okoImg} alt="Podgląd profilu" />
                  <ZoomHint>Kliknij, aby powiększyć</ZoomHint>
                </ImageWrapper>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandIcon>+</ExpandIcon>}
              aria-controls="panel9-content"
              id="panel9-header"
            >
              <Typography>10. Jak załączę plik z prezentacją?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              W menu po lewej wybierz "Edytuj swój profil". Następnie przewiń do części "Moje prezentacje". Żeby dodać plik z prezentacją kliknij "Dodaj prezentację z dysku".

                <ImageWrapper onClick={() => handleImageClick(prezentacjeImg)}>
                  <img src={prezentacjeImg} alt="Sekcja z prezentacjami" />
                  <ZoomHint>Kliknij, aby powiększyć</ZoomHint>
                </ImageWrapper>
                <br/>
                <WarningText>
                  UWAGA! Plik musi być w formacie pdf, a maksymalny rozmiar to 10MB. Możesz zamieścić do 10 prezentacji.
                </WarningText>
                <br/>
                Kliknij "Wybierz plik z dysku", żeby wybrać plik z Twojego urządzenia. W polu "Nazwa wyświetlana" wpisz nazwę pod jaką prezentacja będzie widoczna dla innych. Kliknij "Potwierdź", żeby prezentacja pojawiła się na Twoim profilu. 
                <ImageWrapper onClick={() => handleImageClick(dodawanieImg)}>
                  <img src={dodawanieImg} alt="Dodawanie prezentacji" />
                  <ZoomHint>Kliknij, aby powiększyć</ZoomHint>
                </ImageWrapper>
                <br/>
                Jeśli chcesz zmienić kolejność wyświetlania prezentacji – po prostu przeciągnij element i upuść go na wybraną pozycję. Następnie zapisz zmiany przyciskiem "Zapisz" w prawym górnym rogu.
<br/>
<br/>
Żeby usunąć prezentację kliknij w ikonę kosza na śmieci na prawo od nazwy prezentacji. Usunięcie prezentacji jest nieodwracalne, ale zawsze możesz ją dodać ponownie.
<br/>
<br/>
<ImageWrapper onClick={() => handleImageClick(usuwanieImg)}>
  <img src={usuwanieImg} alt="Usuwanie prezentacji" />
  <ZoomHint>Kliknij, aby powiększyć</ZoomHint>
</ImageWrapper>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandIcon>+</ExpandIcon>}
              aria-controls="panel10-content"
              id="panel10-header"
            >
              <Typography>11. Czy strona jest tajna?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              Przeciętny Kowalski nigdy jej nie znajdzie. Ambitny haker nie napotka hasła, które miałby złamać, a bez indywidualnego adresu też nic nie zobaczy. Strona nie jest skatalogowana ani wyszukiwalna przez Google. Trzeba mieć swój indywidualny link, żeby się do niej dostać.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandIcon>+</ExpandIcon>}
              aria-controls="panel11-content"
              id="panel11-header"
            >
              <Typography>12. Czy mogę coś wydrukować?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              Oczywiście. Po wejściu na czyjś profil, użyj funkcji drukowania w swojej przeglądarce lub telefonie, a na komputerze użyj na klawiaturze skrótu ctrl+p (Windows) albo cmd+p (MacOS).
              </Typography>
            </AccordionDetails>
          </Accordion>
        </AccordionsWrapper>
      </ContentWrapper>

      <Modal
        open={isImageModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="image-modal"
        onClick={handleCloseModal}
      >
        <ModalContent>
          <img src={modalImage} alt="Powiększony obraz" />
        </ModalContent>
      </Modal>
    </VipPageWrapper>
  )
}

export default Instructions

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 24px;
  background: #fff;

  ${mobileCss(`
    flex-direction: column;
    margin-bottom: 10px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  `)};
`

const Title = styled.div`
  font-size: 24px;

  ${mobileCss(`
    width: auto;
    font-size: 18px;
    margin-bottom: 8px;
  `)};
`

const InstructionsTitle = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.secondary};

  ${mobileCss(`
    margin-top: 16px;
  `)};
`

const AccordionsWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding-bottom: 50px;
  
  .MuiAccordion-root {
    margin-bottom: 16px;
    border-radius: 8px;
    overflow: hidden;
    
    &:before {
      display: none;
    }
  }

  .MuiAccordionSummary-root {
    background-color: #f5f5f5;
    transition: all 0.3s ease;

    &:hover {
      background-color: ${({ theme }) => theme.colors.primary};
      color: #fff;
    }

    .MuiTypography-root {
      font-weight: 500;
    }
  }

  .MuiAccordionDetails-root {
    padding: 24px;
    background-color: #fff;

    .MuiTypography-root {
      color: ${({ theme }) => theme.colors.secondary};
      
      a {
        color: ${({ theme }) => theme.colors.primary};
        text-decoration: none;
        
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`

const ExpandIcon = styled.span`
  font-size: 16px;
  transition: transform 0.3s ease;
  color: inherit;

  .Mui-expanded & {
    transform: rotate(45deg);
    color: #fff;
  }
`

const ZoomHint = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.2s ease;
`

const ImageWrapper = styled.div`
  margin-top: 16px;
  position: relative;
  cursor: pointer;
  
  img {
    max-width: 100%;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: transform 0.2s ease;
  }

  &:hover {
    img {
      transform: scale(1.02);
    }
    ${ZoomHint} {
      opacity: 1;
    }
  }
`

const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  
  img {
    max-width: 90vw;
    max-height: 90vh;
    object-fit: contain;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  }
`

const WarningText = styled.div`
  text-align: center;
  font-weight: bold;
  margin: 16px 0;
`

const ContentWrapper = styled.div`
  ${mobileCss(`
    padding-top: 80px;  // Adjust this value based on your header height
  `)};
`

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  
  ${mobileCss(`
    width: 100%;
    justify-content: center;
    gap: 8px;
  `)}
` 