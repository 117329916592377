import React, { forwardRef, useState } from 'react'
import { supabase } from 'utils/supabase'
import Modal from 'components/Modal'
import { IconTrash } from '@tabler/icons-react'
import { Button, Tooltip } from '@mui/material'
import styled from 'styled-components'

interface IconWrapperProps {
  onClick?: () => void;
}

const IconWrapperWithRef = forwardRef<HTMLDivElement, IconWrapperProps>((props, ref) => (
  <IconWrapper ref={ref} onClick={props.onClick}>
    <IconTrash />
  </IconWrapper>
));

const RemoveVipModal = ({ onRemove, id }: { onRemove: () => void, id: string }) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleRemoveVip = async () => {
    try {
      await supabase
        .from('vip_links')
        .delete()
        .eq('id', id)

      handleClose()

      onRemove()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <Tooltip title='Usuń'>
        <IconWrapperWithRef onClick={handleOpen} />
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        title='Usuń członka'
      >
        <ModalContent>
          Czy na pewno chcesz usunąć tę osobę?
          <ButtonsWrapper>
            <Button variant='outlined' onClick={handleClose}>Anuluj</Button>
            <Button variant='contained' color='primary' onClick={handleRemoveVip}>Usuń</Button>
          </ButtonsWrapper>
        </ModalContent>
      </Modal>
    </>
  )
}

export default RemoveVipModal

const IconWrapper = styled.div`
  cursor: pointer;
`

const ModalContent = styled.div`
  text-align: center;
`

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-top: 30px;
`
