import React, { useEffect } from 'react'
import styled from 'styled-components'
import { mobileCss } from 'utils/theme'
import Button from 'components/Button'
import { useNavigate } from 'react-router-dom'
import { VipTableRowTypes } from 'utils/vipTableRowTypes'
import { Presentation } from 'utils/types'

const sanitizeHtml = (html: string | undefined): string => {
  if (!html) return '';
  
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;
  
  // Find all links
  const links = tempDiv.getElementsByTagName('a');
  
  Array.from(links)
    .reverse()
    .forEach(link => {
      const href = link.getAttribute('href');
      
      if (href) {
        // Extract the actual URL from potentially malformed href
        let cleanUrl = href;
        
        // Remove any localhost or whoiswho prefix
        if (href.includes('localhost:') || href.includes('whoiswhoprb.pl')) {
          cleanUrl = href.split('/').pop() || '';
        }
        
        // Add protocol if missing
        if (!cleanUrl.startsWith('http://') && !cleanUrl.startsWith('https://') && 
            !cleanUrl.startsWith('mailto:') && !cleanUrl.startsWith('tel:')) {
          cleanUrl = `http://${cleanUrl}`;
        }
        
        // Update link attributes
        link.setAttribute('href', cleanUrl);
        link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noopener noreferrer');
      }
    });
  
  return tempDiv.innerHTML;
};

const VipPreview = ({ vip }: { vip: VipTableRowTypes | null }) => {
  const navigate = useNavigate()
  
  const presentationLinks = Array.from({ length: 10 }, (_, i) => ({
    titleKey: `vipPresentationTitle${i + 1}`,
    linkKey: `vipPresentationLink${i + 1}`
  }))
  
  const renderHTMLContent = (content: string | undefined) => {
    return { __html: sanitizeHtml(content) }
  }
  
  const handleGoBack = () => {
    navigate(-1)
  }
  
  const formatUrl = (url: string | null | undefined) => {
    if (!url) return ''
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      return `http://${url}`
    }
    return url
  }

  const isContentEmpty = (content: string | undefined | null): boolean => {
    if (!content) return true;
    
    // Remove all HTML tags but preserve their content
    const textContent = content.replace(/<[^>]*>/g, '');
    
    // Remove ALL whitespace characters (space, tab, newline)
    return textContent.replace(/\s/g, '') === '';
  };

  const hasPresentations = (vip?.presentations && vip.presentations.length > 0)

  const getPresentationValue = (key: keyof VipTableRowTypes): string => {
    const value = vip?.[key]
    if (Array.isArray(value)) return ''
    return value?.toString() || ''
  }

  return (
    <VipWrapper>
      <PersonalWrapper>
        <FullName>{vip?.vipFullName}</FullName>
        <Picture>
          {vip?.vipImageUrl && (
            <img style={{ width: 250 }} src={vip.vipImageUrl} alt='vip image' />
          )}
        </Picture>
        <Title>{vip?.vipTitle}</Title>
      </PersonalWrapper>
      <Divider />
      
      {!isContentEmpty(vip?.vipDescriptionP1) && (
        <>
          <ParagraphHeadline>O mnie</ParagraphHeadline>
          <Paragraph dangerouslySetInnerHTML={renderHTMLContent(vip?.vipDescriptionP1)} />
          <Divider />
        </>
      )}
      
      {!isContentEmpty(vip?.vipDescriptionP2) && (
        <>
          <ParagraphHeadline>Moje firmy</ParagraphHeadline>
          <Paragraph dangerouslySetInnerHTML={renderHTMLContent(vip?.vipDescriptionP2)} />
          <Divider />
        </>
      )}
      
      {!isContentEmpty(vip?.vipDescriptionP3) && (
        <>
          <ParagraphHeadline>Moja działalność społeczna</ParagraphHeadline>
          <Paragraph dangerouslySetInnerHTML={renderHTMLContent(vip?.vipDescriptionP3)} />
          <Divider />
        </>
      )}
      
      {!isContentEmpty(vip?.vipDescriptionP4) && (
        <>
          <ParagraphHeadline>Działalność członków rodziny</ParagraphHeadline>
          <Paragraph dangerouslySetInnerHTML={renderHTMLContent(vip?.vipDescriptionP4)} />
          <Divider />
        </>
      )}
      
      {!isContentEmpty(vip?.vipDescriptionP5) && (
        <>
          <ParagraphHeadline>Zainteresowania/hobby</ParagraphHeadline>
          <Paragraph dangerouslySetInnerHTML={renderHTMLContent(vip?.vipDescriptionP5)} />
          <Divider />
        </>
      )}
      
      {!isContentEmpty(vip?.vipDescriptionP6) && (
        <>
          <ParagraphHeadline>Dodatkowe informacje</ParagraphHeadline>
          <Paragraph dangerouslySetInnerHTML={renderHTMLContent(vip?.vipDescriptionP6)} />
          <Divider />
        </>
      )}

      {hasPresentations && (
        <>
          <PresentationsSection>
            <ParagraphHeadline>Moje prezentacje</ParagraphHeadline>
            <div className="buttons-grid">
              {vip?.presentations?.map((presentation) => (
                <PresentationButton
                  key={presentation.fileName}
                  target='_blank'
                  rel='noopener noreferrer'
                  href={presentation.url}
                >
                  {presentation.displayName}
                </PresentationButton>
              ))}
            </div>
          </PresentationsSection>
          <Divider />
        </>
      )}

      <ContactWraper>
        {vip?.vipWebsite && (
          <PersonalLink href={formatUrl(vip.vipWebsite)} target='_blank'>{vip.vipWebsite}</PersonalLink>
        )}
        {vip?.vipLinkedIn && (
          <PersonalLink href={formatUrl(vip.vipLinkedIn)} target='_blank'>LinkedIn</PersonalLink>
        )}
        {vip?.vipEmail && (
          <PersonalLink href={`mailto:${vip.vipEmail}`}>{vip.vipEmail}</PersonalLink>
        )}
        {vip?.vipMobile && (
          <Mobile>{vip.vipMobile}</Mobile>
        )}
      </ContactWraper>
      <Divider />
      <Button onClick={handleGoBack}>Powrót do listy</Button>
    </VipWrapper>
  )
}


export default VipPreview

const VipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 800px;
  padding-top: 20px;
  margin-left: 40px;
  margin-top: 60px;

  @media (max-width: 1036px) {
    width: 100%;
    margin-left: 0;
  }
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #b3b3b3;
  margin: 24px 0;
`
const PersonalWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`

const FullName = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 48px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  ${mobileCss(`
    font-size: 26px;
  `)};
`
const Picture = styled.div`
`
const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 8px;
`
const ShortDescription = styled.div`
  text-align: center;
  font-weight: bold;
`

const Paragraph = styled.div`
  padding-top: 14px;
`
const PresentationsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .buttons-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  @media (max-width: 1036px) {
    .buttons-grid {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
`
const PresentationButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  text-align: center;
  padding: 5px 10px;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primary};
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  }
`
const ContactWraper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  justify-items: center;
  align-items: center;

  @media (max-width: 1036px) {
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    
  }
`
const PersonalLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
`
const ParagraphHeadline = styled.div`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};

  @media (max-width: 1036px) {
    padding-bottom: 5px;
    // border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
  }
`
const Mobile = styled.div`

`
