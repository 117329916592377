export const vipFields = [
  {
    label: 'Imię i nazwisko',
    key: 'vipFullName',
    type: 'input'
  },
  {
    label: 'Nazwisko do celu sortowania',
    key: 'vipSortingName',
    type: 'input'
  },
  {
    label: 'Stanowiska',
    key: 'vipTitle',
    type: 'input'
  },
  {
    label: 'O mnie',
    key: 'vipDescriptionP1',
    type: 'wysiwyg'
  },
  {
    label: 'Moje firmy',
    key: 'vipDescriptionP2',
    type: 'wysiwyg'
  },
  {
    label: 'Moja działalność społeczna',
    key: 'vipDescriptionP3',
    type: 'wysiwyg'
  },
  {
    label: 'Działalność członków rodziny',
    key: 'vipDescriptionP4',
    type: 'wysiwyg'
  },
  {
    label: 'Zainteresowania/hobby',
    key: 'vipDescriptionP5',
    type: 'wysiwyg'
  },
  {
    label: 'Dodatkowe informacje',
    key: 'vipDescriptionP6',
    type: 'wysiwyg'
  },
  {
    label: 'Strona www',
    key: 'vipWebsite',
    type: 'input'
  },
  {
    label: 'Profil LinkedIn',
    key: 'vipLinkedIn',
    type: 'input'
  },
  {
    label: 'Email do kontaktu',
    key: 'vipEmail',
    type: 'input'
  },
  {
    label: 'Telefon',
    key: 'vipMobile',
    type: 'input'
  }
]

export interface FormFieldsTypes {
  label: string
  key: string
}

export const getFormInitialValues = (fields: FormFieldsTypes[]) => {
  let v = {}
  
  const getValue = (type: string) => {
    switch (type) {
      case 'input':
      case 'image':
        return ''
      default:
        return ''
    }
  }
  fields.forEach(field => {
    // @ts-ignore
    v[field.key] = getValue(field.type)
  })
  
  return v
}

// export const findTableSize = (data: TableValues) => {
//   let maxColumn = 0
//   let maxRow = 0
//
//   for (const key in data) {
//     // @ts-ignore
//     if (data.hasOwnProperty(key) && data[key] !== '') {
//       const [, row, column] = key.match(/td(\d)(\d)/) || []
//
//       if (column && row) {
//         maxColumn = Math.max(maxColumn, parseInt(column, 10))
//         maxRow = Math.max(maxRow, parseInt(row, 10))
//       }
//     }
//   }
//
//   return { columns: maxColumn, rows: maxRow };
// }
