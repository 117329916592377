import React, { ChangeEvent, useEffect, useState } from 'react'
import { TextField } from '@mui/material'
import styled from 'styled-components'
import { supabase } from 'utils/supabase'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { omit } from 'ramda'
import { getFormInitialValues, vipFields } from 'utils/vips'
import ImageUploader from 'admin/components/ImageUploader'
import { VipTableRowTypes } from 'utils/vipTableRowTypes'
import Button from 'components/Button'
import WysiwygEditor from 'components/WysiwygEditor'
import { mobileCss } from 'utils/theme'
import PresentationManager from '../PresentationManager'
import { Presentation } from 'utils/types'

const fields = vipFields

interface VipFormProps {
  vip?: VipTableRowTypes
}

// const requiredKeys = ['vipFullName'];
const requiredKeys = ['vipFullName', 'vipTitle', 'vipDescriptionP1', 'vipDescriptionP2', 'vipDescriptionP3'];

const VipForm = ({ vip }: VipFormProps) => {
  const [values, setValues] = useState<Partial<VipTableRowTypes>>(getFormInitialValues(fields))
  const [presentations, setPresentations] = useState<Presentation[]>([])
  const navigate = useNavigate()

  
  const handleValueChange = (name: string) => (e: ChangeEvent<HTMLInputElement>) => {
    setValues(prev => ({ ...prev, [name]: e.target.value }))
  }

  const handleCustomValueChange = (name: string, value: string | null) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }
  
  useEffect(() => {
    if (vip) {
      const initialValues = omit(['id'], vip)
      setValues(initialValues)
      setPresentations(vip.presentations || [])
    }
  }, [vip])
  
  const handleUploadFile = (url: string) => {
    setValues(prev => ({ ...prev, vipImageUrl: url }))
  }

  const handleRemoveImage = () => {
    setValues(prev => ({ ...prev, vipImageUrl: null }))
  }
  
  const handleGoBack = () => {
    navigate(-1)
  }

  const generateFields = () => {
    return (
      <>
        {fields.map(field => {
          if (field.type === 'input') {
            return (
              <TextField
                key={field.key}
                id={field.label}
                size='small'
                fullWidth
                multiline
                value={values[field.key as keyof VipTableRowTypes]?.toString() || ''}
                onChange={handleValueChange(field.key)}
                label={field.label}
                variant='outlined'
              />
            )
          }
          
          if (field.type === 'wysiwyg') {
            const isLastWysiwyg = field.key === 'vipDescriptionP6'
            return (
              <>
                <WysiwygEditor
                  key={field.key}
                  name={field.key}
                  label={field.label}
                  onChange={handleCustomValueChange}
                  value={values[field.key as keyof VipTableRowTypes]?.toString() || ''}
                />
                {isLastWysiwyg && (
                  <PresentationManager 
                    presentations={presentations}
                    onUpdate={setPresentations}
                  />
                )}
              </>
            )
          }

          return <div key={field.key}>{field.type}</div>
        })}
      </>
    )
  }

  const handleSubmit = async () => {
    try {
      await supabase.from('vip_links').update([{
        ...values,
        presentations
      }]).eq('id', vip?.id || '')
      toast.success('Pomyślnie zapisano zmiany')
    } catch (err) {
      toast.error('Wystąpił błąd podczas zapisu')
      console.error(err)
    }
  }

  return (
    <>
      <Header>
        <Title>Edycja danych</Title>
        <Buttons>
          <Button onClick={handleGoBack}>Powrót</Button>
          <Button onClick={handleSubmit}>Zapisz</Button>
        </Buttons>
      </Header>
      <FormWrapper>
        {values.vipImageUrl ? (
          <>
            <img style={{ width: '200px', height: 'auto' }} src={values.vipImageUrl} alt='vip img' />
          </>
        ) : <Placeholder />}
        <ImageSection>
          <ImageUploader onUpload={handleUploadFile} />
          <Button onClick={handleRemoveImage}>Usuń zdjęcie</Button>
        </ImageSection>
        {generateFields()}
      </FormWrapper>
    </>
  )
}

export default VipForm

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .ql-editor {
    min-height: 100px !important;
  }
`
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  
  ${mobileCss(`
    flex-direction: column;
  `)};
`
const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const Title = styled.div`
  font-size: 24px;
`

const ImageSection = styled.div`
  display: flex;
  gap: 10px;

  ${mobileCss(`
    flex-direction: column;
  `)};
`

const Placeholder = styled.div`
  width: 200px;
  height: 200px;
  border: 3px solid ${({ theme }) => theme.colors.primary};
  border-radius: 6px;
`
