import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { supabase } from 'utils/supabase'
import { Button, Modal, TextField } from '@mui/material'
import styled from 'styled-components'
import VipPageWrapper from 'admin/components/VipPageWrapper'
import { VipTableRowTypes } from 'utils/vipTableRowTypes'
import VipsTable from 'admin/components/VipsTable'
import { mobileCss } from 'utils/theme'
import emailjs from '@emailjs/browser'
import toast from 'react-hot-toast'

interface ContactFormData {
  message: string
}

const defaultFormValues: ContactFormData = {
  message: ''
}

export interface VipTypes {
  code: string
  id: string
  fullName: string
  vipFullName: string
  vipSortingName: string
}

const VipList = () => {
  const [vips, setVips] = useState<VipTableRowTypes[]>([])
  const [isContactModalOpen, setIsContactModalOpen] = useState(false)
  const [formData, setFormData] = useState<ContactFormData>(defaultFormValues)
  const [formErrors, setFormErrors] = useState<Partial<ContactFormData>>({})
  const [currentUser, setCurrentUser] = useState<VipTableRowTypes | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  
  const navigate = useNavigate()
  const { code } = useParams()
  
  const getVips = async () => {
    try {
      const response = await supabase
        .from('vip_links')
        .select('*')
      
      const data = response.data as VipTableRowTypes[]
      
      setVips(data)
      
    } catch (err) {
      console.error(err)
    }
  }
  
  const getCurrentUser = async (code: string) => {
    try {
      const { data, error } = await supabase
        .from('vip_links')
        .select('*')
        .eq('code', code)
        .single()

      if (data) {
        setCurrentUser(data)
        setFormData(prev => ({
          ...prev,
          message: ''
        }))
      }
    } catch (err) {
      console.error(err)
    }
  }
  
  useEffect(() => {
    console.log('Current code:', code)
    if (!code) {
      // If no code, we're in admin view
      getVips()
    } else {
      // Validate code and then get vips
      getVips()
      getCurrentUser(code)
    }
  }, [code])
  
  const handleReportClick = () => {
    setIsContactModalOpen(true)
  }

  const handleContactModalClose = () => {
    setIsContactModalOpen(false)
    setFormData(defaultFormValues)
    setFormErrors({})
  }

  const validateForm = () => {
    const errors: Partial<ContactFormData> = {}
    if (!formData.message.trim()) errors.message = 'Wiadomość jest wymagana'
    
    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (validateForm()) {
      try {
        setIsSubmitting(true)
        
        if (!currentUser) {
          toast.error('Nie można znaleźć danych użytkownika')
          return
        }

        emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY!)

        const response = await emailjs.send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID!,
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID!,
          {
            from_name: currentUser.vipFullName || 'Nieznany użytkownik',
            from_email: currentUser.vipEmail || 'brak@email.com',
            message: formData.message,
            user_code: code || 'brak kodu',
            to_name: 'Administrator WhoIsWho'
          },
          process.env.REACT_APP_EMAILJS_PUBLIC_KEY!
        )

        if (response.status === 200) {
          toast.success('Wiadomość została wysłana')
          handleContactModalClose()
        } else {
          toast.error('Wystąpił błąd podczas wysyłania wiadomości')
        }
      } catch (error) {
        console.error('Email error:', error)
        toast.error('Wystąpił błąd podczas wysyłania wiadomości')
      } finally {
        setIsSubmitting(false)
      }
    }
  }

  const handleInputChange = (field: keyof ContactFormData) => (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData(prev => ({ ...prev, [field]: e.target.value }))
    // Clear error when user starts typing
    if (formErrors[field]) {
      setFormErrors(prev => ({ ...prev, [field]: undefined }))
    }
  }

  // @ts-ignore
  return (
    <VipPageWrapper>
      <Header>
        <Title>
         Who is Who w PRB
        </Title>
        <ButtonsWrapper>
          <Button 
            variant='outlined' 
            onClick={() => navigate(`/${code}/broszura/wszyscy`)}
          >
            Zobacz wszystkich
          </Button>
        </ButtonsWrapper>
      </Header>
      <VipsTable
        vips={vips}
        onRemove={getVips}
      />

      <Modal
        open={isContactModalOpen}
        onClose={handleContactModalClose}
        aria-labelledby="contact-form-modal"
      >
        <ModalContent>
          <ModalTitle>Zgłoś problem</ModalTitle>
          <Form onSubmit={handleSubmit}>
            <TextField
              required
              fullWidth
              label="Wiadomość"
              multiline
              rows={4}
              value={formData.message}
              onChange={handleInputChange('message')}
              error={!!formErrors.message}
              helperText={formErrors.message}
              variant="outlined"
            />
            <ButtonsWrapper>
              <Button 
                variant="outlined" 
                onClick={handleContactModalClose}
                sx={{ marginRight: 1 }}
              >
                Anuluj
              </Button>
              <Button 
                variant="contained" 
                type="submit"
                color="primary"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Wysyłanie...' : 'Wyślij'}
              </Button>
            </ButtonsWrapper>
          </Form>
        </ModalContent>
      </Modal>
    </VipPageWrapper>
  )
}

export default VipList

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  ${mobileCss(`
  flex-direction: column,
  margin-bottom: 10px;
  `)};
`

const Title = styled.div`
  font-size: 24px;

  ${mobileCss(`
   width: auto;
   font-size: 18px;
  `)};
`

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  
  ${mobileCss(`
    width: 100%;
    justify-content: center;
    gap: 8px;
  `)}
`

const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 32px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  outline: none;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
`

const ModalTitle = styled.h2`
  margin: 0 0 24px;
  color: ${({ theme }) => theme.colors.secondary};
  text-align: center;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
