import React, { useState, useRef } from 'react'
import { Button, TextField, Modal } from '@mui/material'
import styled from 'styled-components'
import { mobileCss } from 'utils/theme'
import { Presentation } from 'utils/types'
import { IconTrash } from '@tabler/icons-react'
import { toast } from 'react-hot-toast'
import { supabase } from 'utils/supabase'
import { generateRandomString } from 'utils/generators'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'

interface Props {
  presentations: Presentation[]
  onUpdate: (presentations: Presentation[]) => void
  vipId?: string
}

const PresentationManager = ({ presentations, onUpdate, vipId }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [presentationToDelete, setPresentationToDelete] = useState<Presentation | null>(null)
  const [displayName, setDisplayName] = useState('')
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [isUploading, setIsUploading] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const MAX_NAME_LENGTH = 80
  const MAX_PRESENTATIONS = 10

  const handleFileSelect = (file: File) => {
    setSelectedFile(file)
  }

  const handleConfirm = async () => {
    if (!selectedFile || !displayName.trim() || !vipId) return;

    try {
      setIsUploading(true);
      const fileExtensionRe = /.*\.(.*)/.exec(selectedFile.name) || [];
      const newFileName = `${generateRandomString()}.${fileExtensionRe[1]}`;
      const renamedFile = new File([selectedFile], newFileName, { type: selectedFile.type });

      const { data, error } = await supabase
        .storage
        .from('presentations_wiw')
        .upload(newFileName, renamedFile);

      if (error) {
        toast.error('Coś poszło nie tak');
        return;
      }

      const { data: presentationData } = supabase.storage
        .from('presentations_wiw')
        .getPublicUrl(data.path);

      if (presentationData) {
        const newPresentation: Presentation = {
          fileName: newFileName,
          displayName: displayName.trim(),
          url: presentationData.publicUrl,
          position: presentations.length + 1
        };
        
        const updatedPresentations = [...presentations, newPresentation];
        
        // Save to database
        await supabase.from('vip_links')
          .update({ presentations: updatedPresentations })
          .eq('id', vipId);

        onUpdate(updatedPresentations);
        toast.success('Prezentacja została dodana');
        handleCloseModal();
      }
    } catch (error) {
      console.error('Error uploading presentation:', error);
      toast.error('Wystąpił błąd podczas dodawania prezentacji');
    } finally {
      setIsUploading(false);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setDisplayName('')
    setSelectedFile(null)
  }

  const handleRemove = (presentation: Presentation) => {
    setPresentationToDelete(presentation)
    setIsDeleteModalOpen(true)
  }

  const confirmDelete = async () => {
    if (presentationToDelete && vipId) {
      try {
        // Get the filename from the presentation object
        const fileName = presentationToDelete.fileName;
        
        // Remove from storage
        const { error: storageError } = await supabase
          .storage
          .from('presentations_wiw')
          .remove([fileName]);

        if (storageError) {
          console.error('Error removing file from storage:', storageError);
        }

        // Update database
        const updatedPresentations = presentations.filter(p => p.position !== presentationToDelete.position);
        const reorderedPresentations = updatedPresentations.map((item, index) => ({
          ...item,
          position: index + 1
        }));

        await supabase.from('vip_links')
          .update({ presentations: reorderedPresentations })
          .eq('id', vipId);

        onUpdate(reorderedPresentations);
        toast.success('Prezentacja została usunięta');
      } catch (err) {
        console.error('Error removing presentation:', err);
        toast.error('Wystąpił błąd podczas usuwania prezentacji');
      }

      setIsDeleteModalOpen(false);
      setPresentationToDelete(null);
    }
  };

  const handleFileButtonClick = () => {
    fileInputRef.current?.click()
  }

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const items = Array.from(presentations);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // Update positions after reorder
    const updatedItems = items.map((item, index) => ({
      ...item,
      position: index + 1
    }));

    onUpdate(updatedItems);
  };

  return (
    <Wrapper>
      <Title>Moje prezentacje</Title>
      <InfoList>
        <li>Plik musi być w formacie pdf</li>
        <li>Możesz wgrać maksymalnie {MAX_PRESENTATIONS} prezentacji</li>
        <li>Maksymalny rozmiar pliku to 10MB</li>
        <li>Przeciągnij i upuść aby zmienić kolejność</li>
      </InfoList>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="presentations">
          {(provided) => (
            <PresentationsList
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {presentations.map((presentation, index) => (
                <Draggable 
                  key={presentation.fileName} 
                  draggableId={presentation.fileName} 
                  index={index}
                >
                  {(provided, snapshot) => (
                    <PresentationRow
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      $isDragging={snapshot.isDragging}
                    >
                      <DragHandle>⋮⋮</DragHandle>
                      <PresentationLink 
                        href={presentation.url} 
                        target="_blank" 
                        rel="noopener noreferrer"
                      >
                        {presentation.displayName}
                      </PresentationLink>
                      <Button 
                        variant="outlined" 
                        color="error"
                        onClick={() => handleRemove(presentation)}
                      >
                        <IconTrash size={20} />
                      </Button>
                    </PresentationRow>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </PresentationsList>
          )}
        </Droppable>
      </DragDropContext>
      {presentations.length >= MAX_PRESENTATIONS ? (
        <Button 
          variant="outlined" 
          disabled
          sx={{ marginTop: 2 }}
          title={`Osiągnięto limit ${MAX_PRESENTATIONS} prezentacji`}
        >
          Dodaj prezentację z dysku (limit {MAX_PRESENTATIONS})
        </Button>
      ) : (
        <Button 
          variant="outlined" 
          onClick={() => setIsModalOpen(true)}
          sx={{ marginTop: 2 }}
        >
          Dodaj prezentację z dysku
        </Button>
      )}

      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
      >
        <ModalContent>
          <ModalTitle>Dodaj prezentację</ModalTitle>
          <FileInputWrapper>
            <input
              ref={fileInputRef}
              type="file"
              accept=".pdf"
              style={{ display: 'none' }}
              onChange={(e) => {
                if (e.target.files?.[0]) {
                  const file = e.target.files[0]
                  if (file.type !== 'application/pdf') {
                    toast.error('Dozwolone są tylko pliki PDF')
                    return
                  }
                  if (file.size > 10 * 1024 * 1024) {
                    toast.error('Maksymalny rozmiar pliku to 10MB')
                    return
                  }
                  handleFileSelect(file)
                }
              }}
            />
            <Button
              variant="outlined"
              onClick={handleFileButtonClick}
              fullWidth
            >
              {selectedFile ? selectedFile.name : 'Wybierz plik PDF z dysku'}
            </Button>
          </FileInputWrapper>
          <TextField
            fullWidth
            required
            label="Nazwa wyświetlana"
            value={displayName}
            onChange={(e) => {
              if (e.target.value.length <= MAX_NAME_LENGTH) {
                setDisplayName(e.target.value)
              }
            }}
            helperText={`To pole jest wymagane. Pozostało znaków: ${MAX_NAME_LENGTH - displayName.length}`}
            error={displayName.length >= MAX_NAME_LENGTH || displayName.trim() === ''}
            sx={{ marginTop: 2 }}
          />
          <ButtonsWrapper>
            <Button 
              variant="outlined" 
              onClick={handleCloseModal}
            >
              Anuluj
            </Button>
            <Button 
              variant="contained"
              onClick={handleConfirm}
              disabled={!selectedFile || !displayName.trim() || isUploading}
            >
              {isUploading ? 'Dodawanie...' : 'Potwierdź'}
            </Button>
          </ButtonsWrapper>
        </ModalContent>
      </Modal>

      <Modal open={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
        <ModalContent>
          <ModalTitle>Potwierdź usunięcie</ModalTitle>
          <DeleteModalText>
            Czy na pewno chcesz usunąć prezentację:
            <PresentationName>
              {presentationToDelete?.displayName}
            </PresentationName>
          </DeleteModalText>
          <ButtonsWrapper>
            <Button 
              variant="outlined" 
              onClick={() => setIsDeleteModalOpen(false)}
            >
              Anuluj
            </Button>
            <Button 
              variant="contained"
              color="error"
              onClick={confirmDelete}
            >
              Usuń
            </Button>
          </ButtonsWrapper>
        </ModalContent>
      </Modal>
    </Wrapper>
  )
}

export default PresentationManager

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
`

const PresentationsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const PresentationRow = styled.div<{ $isDragging: boolean }>`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 8px;
  background: ${props => props.$isDragging ? '#f5f5f5' : 'transparent'};
  border-radius: 4px;
  border: 1px solid ${props => props.$isDragging ? '#e0e0e0' : 'transparent'};

  ${mobileCss(`
    flex-direction: column;
    align-items: stretch;
  `)};
`

const PresentationLink = styled.a`
  flex: 1;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};
  &:hover {
    text-decoration: underline;
  }
`

const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 32px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const ModalTitle = styled.h2`
  margin: 0;
  color: ${({ theme }) => theme.colors.secondary};
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 16px;
`

const FileInputWrapper = styled.div`
  width: 100%;
`

const InfoList = styled.ul`
  font-style: italic;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  margin: 8px 0;
  padding-left: 20px;
`

const DeleteModalText = styled.div`
  text-align: center;
  line-height: 1.6;
`

const PresentationName = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
  margin: 8px 0;
`

const DragHandle = styled.div`
  cursor: grab;
  color: #757575;
  user-select: none;
  font-size: 20px;
  line-height: 1;
  padding: 0 4px;

  &:active {
    cursor: grabbing;
  }
` 