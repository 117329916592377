import React, { forwardRef, useState } from 'react'
import Modal from 'components/Modal'
import { Tooltip } from '@mui/material'
import { IconListCheck } from '@tabler/icons-react'
import styled from 'styled-components'
import { VipTypes } from 'admin/pages/adminPages/AdminVipList'

interface IconWrapperProps {
  onClick?: () => void;
}

const IconWrapperWithRef = forwardRef<HTMLDivElement, IconWrapperProps>((props, ref) => (
  <IconWrapper ref={ref} onClick={props.onClick}>
    <IconListCheck />
  </IconWrapper>
));

const VipLinkModal = ({ vip }: { vip: VipTypes }) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Tooltip title='Link profilu'>
        <IconWrapperWithRef onClick={handleOpen} />
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        title='Link profilu'
      >
        <Title>Unikalne linki dla <span>{vip.vipFullName}</span>:</Title>
        <Voting>
          <Value>{`${process.env.REACT_APP_URL}/${vip.code}`}</Value>
          {/*<Value>{`localhost:3001/${vip.code}`}</Value>*/}
        </Voting>
      </Modal>
    </>
  )
}

export default VipLinkModal

const IconWrapper = styled.div`
  cursor: pointer;
`

const Title = styled.div`
  margin-bottom: 16px;
  
  span {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.secondary};
  }
`

const Value = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.primary};
`

const Voting = styled.div`
  margin-bottom: 20px;
`
