import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, TextField, Tooltip } from '@mui/material';
import styled from 'styled-components';
import { supabase } from 'utils/supabase';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { omit } from 'ramda';
import { getFormInitialValues, vipFields } from 'utils/vips';
import ImageManager from 'admin/components/ImageManager';
import { VipTableRowTypes } from 'utils/vipTableRowTypes';
import WysiwygEditor from 'components/WysiwygEditor';
import { mobileCss } from 'utils/theme';
import PresentationManager from './PresentationManager'
import { Presentation } from 'utils/types'

const fields = vipFields;

interface VipFormProps {
  vip?: VipTableRowTypes;
}
type PresentationKey = `vipPresentationTitle${number}` | `vipPresentationLink${number}`;

// const requiredKeys = ['vipFullName'];
const requiredKeys = ['vipFullName', 'vipTitle', 'vipDescriptionP1', 'vipDescriptionP2', 'vipDescriptionP3'];

const VipForm = ({ vip }: VipFormProps) => {
  const [values, setValues] = useState<Partial<VipTableRowTypes>>(getFormInitialValues(fields));
  const [isFormValid, setIsFormValid] = useState(false);
  const [missingFields, setMissingFields] = useState<string[]>([]);
  const navigate = useNavigate();
  const { code } = useParams();
  const [presentations, setPresentations] = useState<Presentation[]>([])
  const [isSaving, setIsSaving] = useState(false);
  
  const handleValueChange = (name: string) => (e: ChangeEvent<HTMLInputElement>) => {
    setValues(prev => ({ ...prev, [name]: e.target.value }));
  };
  
  const handleCustomValueChange = (name: string, value: string | null) => {
    setValues(prev => ({ ...prev, [name]: value }));
  };
  
  useEffect(() => {
    if (vip) {
      const initialValues = omit(['id'], vip);
      setValues(initialValues);
      // Initialize presentations from vip data
      setPresentations(vip.presentations || []);
    }
  }, [vip]);
  
  useEffect(() => {
    validateForm();
  }, [values]);
  
  const validateForm = () => {
    const missing = requiredKeys.filter(key => {
      const value = values[key as keyof VipTableRowTypes];
      return typeof value !== 'string' || value.trim() === '';
    });
    setMissingFields(missing);
    setIsFormValid(missing.length === 0);
  };
  
  const handleUploadFile = (url: string) => {
    setValues(prev => ({ ...prev, vipImageUrl: url }));
  };
  
  const handleRemoveImage = () => {
    setValues(prev => ({ ...prev, vipImageUrl: null }));
  }
  
  const handleGoBack = () => {
    navigate(-1);
  }
  
  const fieldLabels = {
    vipFullName: 'Pełne imię',
    vipTitle: 'Stanowiska',
    vipDescriptionP1: 'O mnie',
    vipDescriptionP2: 'Moje firmy',
    vipDescriptionP3: 'Moja działalność społeczna'
  }
  
  const missingFieldLabels = missingFields.map(field => fieldLabels[field as keyof typeof fieldLabels])
  
  const generateFields = () => {
    return (
      <>
        {fields.map((field) => {
          if (field.key === 'vipSortingName') {
            return null;
          }
          
          if (field.key === 'vipWebsite') {
            return (
              <>
                <PresentationManager 
                  presentations={presentations}
                  onUpdate={setPresentations}
                  vipId={vip?.id}
                />
                <TextField
                  key={field.key}
                  id={field.label}
                  size='small'
                  fullWidth
                  multiline
                  value={values[field.key as keyof typeof values]?.toString() || ''}
                  onChange={handleValueChange(field.key)}
                  label={field.label}
                  variant='outlined'
                />
              </>
            );
          }
          
          if (field.type === 'input') {
            return (
              <TextField
                key={field.key}
                id={field.label}
                size='small'
                fullWidth
                multiline
                value={values[field.key as keyof typeof values]?.toString() || ''}
                onChange={handleValueChange(field.key)}
                label={field.label}
                variant='outlined'
              />
            );
          }
          
          if (field.type === 'wysiwyg') {
            return (
              <WysiwygEditor
                key={field.key}
                name={field.key}
                label={field.label}
                onChange={handleCustomValueChange}
                value={values[field.key as keyof typeof values]?.toString() || ''}
              />
            );
          }
          
          return <div key={field.key}>{field.type}</div>;
        })}
      </>
    );
  };
  
  const handleSubmit = async () => {
    if (isSaving) return;
    
    try {
      setIsSaving(true);
      await supabase.from('vip_links').update([{
        ...values,
        presentations
      }]).eq('id', vip?.id || '')
      
      // Artificial delay for better UX
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      toast.success('Pomyślnie zapisano zmiany')
    } catch (err) {
      console.error('🔴 Submit error:', err)
      toast.error('Wystąpił błąd podczas zapisu')
    } finally {
      setIsSaving(false);
    }
  }
  
  const handlePreview = async () => {
    if (isSaving) return;
    
    try {
      setIsSaving(true);
      await supabase.from('vip_links').update([{
        ...values,
        presentations
      }]).eq('id', vip?.id || '')
      
      // Artificial delay for better UX
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      toast.success('Pomyślnie zapisano zmiany')
      navigate(`/${code}/podglad/${vip?.id}`)
    } catch (err) {
      console.error('🔴 Submit error:', err)
      toast.error('Wystąpił błąd podczas zapisu')
    } finally {
      setIsSaving(false);
    }
  }
  
  return (
    <>
      <Header>
        <Title>Edycja danych</Title>
        <Buttons>
          <Button 
            variant='outlined' 
            onClick={() => navigate(`/${code}`)}
            sx={{ marginRight: 1 }}
          >
            Powrót
          </Button>
          <SaveButton 
            variant='outlined'
            onClick={handleSubmit}
            disabled={isSaving}
            $isSaving={isSaving}
            sx={{ marginRight: 1 }}
          >
            {isSaving ? 'Zapisywanie...' : 'Zapisz'}
          </SaveButton>
          <Button 
            variant='contained'
            onClick={handlePreview}
            disabled={isSaving}
          >
            Podgląd
          </Button>
        </Buttons>
      </Header>
      <FormWrapper>
        <ImageManager 
          imageUrl={values.vipImageUrl ?? null}
          onUpdate={(url) => handleCustomValueChange('vipImageUrl', url)}
          vipId={vip?.id}
        />
        {generateFields()}
      </FormWrapper>
    </>
  );
};

export default VipForm;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;
  padding-top: 16px;
  margin-top: 60px;

  .ql-editor {
    min-height: 100px !important;
  }
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  position: fixed;
  top: 0;
  left: var(--side-menu-width, 240px);
  right: 0;
  background: white;
  padding: 16px;
  z-index: 1000;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary};

  ${mobileCss(`
    left: 0;
    right: 0;
    flex-direction: column;
  `)};
`;
const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const Title = styled.div`
  font-size: 24px;
`

const ImageSection = styled.div`
  display: flex;
  gap: 10px;

  ${mobileCss(`
    flex-direction: column;
  `)};
`

const Placeholder = styled.div`
  width: 200px;
  height: 200px;
  border: 3px solid ${({ theme }) => theme.colors.primary};
  border-radius: 6px;
`;

const SaveButton = styled(Button)<{ $isSaving: boolean }>`
  position: relative;
  overflow: hidden;
  width: 140px;
  justify-content: center;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: ${props => props.$isSaving ? '100%' : '0'};
    background-color: ${({ theme }) => theme.colors.primary}20;
    transition: width 0.2s ease-out;
  }
`;
